import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit, PLATFORM_ID, Inject, Injectable
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslationService } from './modules/i18n/translation.service';
// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { locale as arLang } from './modules/i18n/vocabs/ar';
import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { TableExtendedService } from './_metronic/shared/crud-table';
import { TranslateService } from '@ngx-translate/core';
import { isPlatformBrowser } from '@angular/common';
import { LoadingServiceService } from './_metronic/core/services/loadingservice/loading-service.service';
import AOS from 'aos';
import 'aos/dist/aos.css';
type DocumentType = Document
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  document: Document;
  lang: any;
  dir: string;
  constructor(
    private translationService: TranslationService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private tableService: TableExtendedService,
    private translateService: TranslateService,
    public _loading: LoadingServiceService,
    //@Inject(Document) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    // register translations
    this.translationService.loadTranslations(
      enLang,
      chLang,
      esLang,
      jpLang,
      deLang,
      frLang,
      arLang
    );
    // router.events.pipe(
    //   filter(event => event instanceof NavigationStart)  
    // ).subscribe((event: NavigationStart) => {
    //   event.url.includes('/offers/') ? this.show = false : this.show = true;
    //  // event.url.includes('/home/') ? this.navhome = false : this.show = true;
    // }); 

    this._loading.isLoading.next(true);
    this.directionChanged();

    if (isPlatformBrowser(this.platformId)) {
      AOS.init()
      setTimeout(() => {
        this._loading.isLoading.next(false);
      }, 1000);
    }
  }

  ngOnInit() {
    //this.document.documentElement.classList.remove("no-scroll");

    console.log(localStorage.getItem("language"));
    const routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // clear filtration paginations and others
        this.tableService.setDefaults();
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        // setTimeout(() => {
        //   document.body.classList.add('page-loaded');
        // }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }
  private directionChanged(): void {
    // const htmlTag = this.document.getElementsByTagName('html')[0] as HTMLHtmlElement;
    this.lang = localStorage.getItem("language");
    console.log(localStorage.getItem("language"));
    if (this.lang == "ar") {
      this.dir = 'rtl';
      // htmlTag.dir = this.dir === 'rtl' ? 'rtl' : 'ltr';
    }
    else {
      this.dir = 'ltr';
      //  htmlTag.dir = this.dir === 'rtl' ? 'rtl' : 'ltr';
    }

  }
  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
